
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Home from './views/Home';
// import Overview from './views/Overview';
import Register from './views/Register';
import Cronjob from './Cronjob';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/overview" element={<Overview />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/cronjob" element={<Cronjob />} />
      </Routes>
    </Router>
  );
}

export default App;
