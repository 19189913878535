import React from 'react';
import { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, getDocs, updateDoc, doc, query, where } from 'firebase/firestore';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { async } from '@firebase/util';

function Cronjob(props) {
    const navigate = useNavigate;
    const [entries, setEntries] = useState([]);
    const entriesRef = collection(db, "entries");
    const q = query(entriesRef, where('isActive', '==', true));


    async function UpdateEntry() {
        const data = await getDocs(q);
        const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id, isActive: false }));
        docs.map((entry) => {
            updateDoc(doc(db, "entries", entry.id), { isActive: false })
        })

    }
    UpdateEntry()

    return (
        <Navigate to='/' />
    );
}

export default Cronjob;