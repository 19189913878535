import { addDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { db } from '../firebase-config';
import { collection, serverTimestamp } from 'firebase/firestore';
import '../styles/register.scss'
import { useNavigate } from 'react-router-dom';
import Home from './Home';

function Register(props) {
    const navigate = useNavigate();
    const entriesRef = collection(db, "entries");
    const [name, setName] = useState("");
    const createEntry = async () => {
        await addDoc(entriesRef, {
            name: name,
            isActive: true,
            timestamp: serverTimestamp(),
        })

    }

    function handleSubmit(event) {
        event.preventDefault();
        createEntry();

        navigate('/');
    }
    return (
        <form onSubmit={handleSubmit}>
            <h1>Registreer je even en begin met studeren</h1>
            <input
                required={true}
                type='text'
                placeholder='Naam'
                onChange={(event) => {
                    setName(event.target.value);
                }}
            />
            <input type="submit" value="Check in!" />
        </form>
    );
}

export default Register;