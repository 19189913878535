import React from 'react';
import { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, where, Timestamp, onSnapshot, doc, query, orderBy, updateDoc } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Table } from 'antd';
import '../styles/table.scss'

function SimpleTable(props) {
    const [entries, setEntries] = useState([]);
    const entriesRef = collection(db, "entries");

    function getStartOfToday() {
        const now = new Date()
        now.setHours(0, 0, 0, 0)
        const timestamp = Timestamp.fromDate(now)
        return timestamp // ex. 1631246400
    }

    const qUpdate = query(entriesRef, where('isActive', '==', true), where('timestamp', '<', getStartOfToday()), orderBy("timestamp", "desc"));

    useEffect(() => {
        const qTable = query(entriesRef, where('isActive', '==', true), where('timestamp', '>', getStartOfToday()), orderBy("timestamp", "desc"));
        const unsub = onSnapshot(qTable, (snapshot) => {
            const datas = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            const entries = datas.map((data) => {
                const time = data.timestamp.toDate();
                const hours = time.getHours();
                var minutes = time.getMinutes() < 10 ? '0' : '';
                minutes += time.getMinutes();

                return {
                    ...data,
                    time: hours + ":" + minutes
                }
            });

            setEntries(entries);
        });

        return unsub;
    }, []
    );


    async function UpdateEntry() {
        const data = await getDocs(qUpdate);
        const docs = data.docs.map((doc) => ({
            ...doc.data(), id: doc.id, isActive: false
        }));
        docs.map((entry) => {
            updateDoc(doc(db, "entries", entry.id), { isActive: false })
        })

    }

    const columns = [
        {
            title: 'Naam',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Check in',
            dataIndex: 'time',
            key: 'time',
            width: '7rem',
            fixed: 'right'
        },

    ];

    UpdateEntry();
    return (
        <div className='table'>
            <Table columns={columns} dataSource={entries} pagination={false} scroll={{ y: 'calc(80vh)' }} />
        </div>
    );
}

export default SimpleTable;