// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCFBcsSaLyvO8-hTHauVEEk7UzPBmABLYo",
    authDomain: "tds-check-in-system.firebaseapp.com",
    projectId: "tds-check-in-system",
    storageBucket: "tds-check-in-system.appspot.com",
    messagingSenderId: "1036820541967",
    appId: "1:1036820541967:web:f956bed552b16c8093a97a",
    measurementId: "G-2YM7LV0V6T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
