
import React from 'react';
import SimpleTable from '../components/SimpleTable';
import { Avatar, Button, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import logo from '../scouts_99e_logo.png';
import '../styles/home.scss'
const { Title } = Typography;
const { Header, Footer, Sider, Content } = Layout;

function Home(props) {

    return (
        <Layout >
            <Header style={{ backgroundColor: '#689B2F' }} className='header'>
                <Avatar style={{ float: 'right', margin: '1em 0' }} size="large" src={logo} />
                <h2>Studeren op de scouts</h2>
            </Header>

            <Content className='content'>
                <Button className='reg-button'>
                    <Link className="reg-link" to='/register'>Registreer je</Link>
                </Button>
                <SimpleTable />
            </Content>
        </Layout >

    );
}

export default Home;